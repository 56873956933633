.copy-link {
  color: #2660f6;
  cursor: pointer;
  text-align: left;
  transition: color 100ms;

  &.inline {
    display: inline;
  }

  &:hover {
    color: #2660f660;
  }
}

.ant-list-items .copy-link {
  min-height: 30px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 4px;
  transition: all 300ms;

  &:hover {
    background-color: #2660f620;
    color: #2660f6;
  }
}