.languages-wrapper {
  width: 125px;
  font-size: 14px;

  .ant-select-selection--single {
    height: auto;

    .ant-select-selection__rendered {
      line-height: 30px;
    }
  }
}
