$mainColor: #2660f6;
$hoverGreen: #2660f660;
$darkGreen: #2660f6;
$lightGreen: #2660f620;
$tableNtnChild: #2660f605;
$borderColor: #e8e8e8;
$textColor: rgba(0, 0, 0, 0.85);
$lightBlue: #2660f660;
$greyColor: #697E8D;

// Mixins

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
}

@mixin transition($time) {
  -webkit-transition: all $time;
  -moz-transition: all $time;
  -ms-transition: all $time;
  -o-transition: all $time;
  transition: all $time;
}

@mixin transform($val) {
  -webkit-transform: $val;
  -moz-transform: $val;
  -ms-transform: $val;
  -o-transform: $val;
  transform: $val;
}

@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
