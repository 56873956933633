@import './variables.scss';

.site-logo {
  padding: 12px;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  display: flex;
  justify-content: center;

  > svg {
    max-height: 40px;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 40px;
  }
}
.side-menu {
  height: calc(100% - 65px);
}
.app-main {
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $lightGreen;
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right-color: $mainColor;
  }
  .ant-menu-item-selected > a,
  .ant-menu-item-selected > a:hover,
  .ant-menu-item > a:hover {
    color: $mainColor;
  }
}
.side-menu__spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-right: 1px solid #e8e8e8;
}
