.logo {
  height: 32px;
  background-color: greenyellow;
  margin: 16px;
}

li.ant-menu-item {
  margin-top: 0 !important;
}

.menu {
  margin-right: 8px;
  min-width: 160px;
}

.header.ant-layout-header {
  background-color: white;
  padding-left: 16px;
  padding-right: 30px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

span.user {
  height: 100%;
  float: right;
  cursor: pointer;
}

.user_avatar {
  margin: 15px !important;
}

.spin {
  margin-left: 8px;
  margin-right: 8px;
}
