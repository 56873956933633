@import '../../../../assets/sass/fonts';

.m-mobile-comment {
  flex-wrap: nowrap;
  margin-bottom: 28px;
  @include switzer-regular;

  &:last-child {
    .m-mobile-comment__avatar-line.reply {
      display: none;
    }

    .m-mobile-comment__author-name {
      color: red
    }
  }

  &__avatar {
    width: 42px;
    height: 42px;
    margin-right: 9px;
    overflow: unset;
    border: 3px solid #fff;

    &.expert {
      width: 42px;
      height: 42px;
      border: 1px solid #2660f6;
    }

    > img {
      border-radius: 50%;
      border: 3px solid #fff;
    }

    &-line {
      &.reply {
        position: absolute;
        left: -30px;
        top: 0;
        transform: translateX(-50%);
        width: 1px;
        height: calc(100% + 28px);
        background-color: #dfe5f6;
      }

      &.main {
        position: absolute;
        left: calc(50% - 5px);
        top: 42px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 1px;
        height: calc(100% - 42px + 28px);
        background-color: #dfe5f6;
      }
    }

    &-reply {
      position: absolute;
      top: -4px;
      left: -30px;
      width: 25px;
      height: 25px;
      border-bottom-left-radius: 16px;
      border-left: 1px solid #dfe5f6;
      border-bottom: 1px solid #dfe5f6;
    }
  }

  &__avatar-holder {
    &.main {
      &:after {
        content: ' ';
      }
    }

    &.reply {
      margin-left: 50px;
    }
  }

  &__content-wrapper {
    background: linear-gradient(0deg, rgba(223, 225, 232, 0.4), rgba(223, 225, 232, 0.4)), #ffffff;
    border-radius: 8px;
    padding: 12px;
    width: 100%;
  }

  &__comment {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    line-height: 18px;
    margin-top: 4px;
    white-space: pre-line;

    &.short {
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }

  &__author-name {
    font-size: 14px;
    line-height: 16px;
    color: #052941;
  }

  &__expert {
    font-size: 12px;
    line-height: 15px;
    color: #2660f6;
    margin: 2px 0;
  }

  &__read-more {
    font-size: 12px;
    line-height: 15px;
    color: #99a0b4;
    cursor: pointer;
  }

  &__actions {
    display: flex;
    flex-wrap: nowrap;
    color: #99a0b4;
    margin-top: 4px;

    &-time {
      width: 100%;
      font-size: 11px;
      line-height: 15px;

      > span {
        &:first-child {
          margin-right: 20px;
        }
      }
    }

    &-like {
      display: flex;
      justify-content: flex-end;
    }
  }
}
