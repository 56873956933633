.dynamic-items {
  .d-flex {
    display: flex;
  }

  .dynamic-delete-button {
    margin-left: 15px;
    color: red;
  }
}
