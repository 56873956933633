.avatar-uploader {
  .ant-upload {
    position: relative;
  }

  .upload-remove {
    display: inline-block;

    .button {
      font-size: 18px;
      position: absolute;
      top: -14px;
      right: -10px;
      color: #f5222d;

      &:hover {
        color: #ff7875;
      }
    }
  }
}

.upload-fill {
  .ant-upload.ant-upload-select-picture-card {
    background-color: #2660f6;
  }
}
