.app-404 {
  display: flex;
  align-items: center;
  height: 80%;
  min-height: 500px;
}
.app-404-right {
  flex: 0 0 58.5%;
  width: 58.5%;
  padding-right: 152px;
  zoom: 1;
}
.app-404-img {
  float: right;
  width: 100%;
  max-width: 430px;
  height: 360px;
  background: url('../images/404.svg') no-repeat 50% 50%;
  background-size: contain;
}
.app-404-left {
  flex: auto;
  h1 {
    margin-bottom: 24px;
    color: #434e59;
    font-weight: 600;
    font-size: 72px;
    line-height: 72px;
  }
  h3 {
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 20px;
    line-height: 28px;
  }
}
