.m-mobile-preview {
  padding-top: 28px;

  &__reply {
    padding-bottom: 15px;
    margin-top: 20px;
  }

  &__responsible-modal {
    padding: 16px;
  }

  &__responsible-select {
    width: 100%;
  }

  &__responsible-submit {
    margin-top: 30px;
  }
}
