.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.text-align-center {
  text-align: center;
}

.error-div {
  text-align: center;
  color: #f5222d;
  margin-bottom: 20px;
}

@for $i from 1 to 4 {
  .gap-#{$i} {
    gap: $i * 4px;
  }
}

.d-block {
  display: block;
}
