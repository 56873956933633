@import 'variables.scss';
@import 'fonts.scss';
@import 'drag.scss';
@import 'helpers.scss';

@import 'node_modules/video-react/styles/scss/video-react';

body {
  @include switzer-regular;
}

.content {
  margin: 24px 16px;
  min-height: 280px;
}

.white-bg {
  background: #fff;
  padding: 16px;
}

.breadcrumb {
  margin: 16px 0;
}

.content {
  min-height: auto;

  .ant-card {
    margin-bottom: 24px;
  }
}

.interaction-center {
  margin: 0 16px 16px;

  p {
    display: block !important;
  }

  img {
    width: 100%;
    height: auto;
  }

  &.notifications-center {
    .ant-comment-actions {
      margin-top: 0;
    }

    .ant-upload-list > div {
      &:first-child {
        .ant-upload-list-item {
          margin-top: 0;
        }
      }

      &:last-child {
        .ant-upload-list-item {
          margin-bottom: 8px;
        }
      }
    }
  }
}

.notifications-center {
  margin: 32px;

  .page-size-filter {
    margin-left: -16px;
    margin-top: -16px;
    padding-bottom: 16px;
  }

  .ant-divider {
    color: #2e49d2;

    &:before,
    &:after {
      border-color: #2e49d2;
    }
  }

  .ant-timeline > li:last-child {
    padding: 0;
  }

  .ant-timeline-item-content {
    margin: 0 0 0 55px;
  }

  .ant-timeline-item-head-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
    width: 46px;
    height: 46px;
    border-radius: 100px;
    background-color: #d9d9d9;

    img {
      width: 32px;
      filter: brightness(0);
    }

    i {
      color: #000000;
      font-size: 32px !important;
    }
  }
}

.map-box {
  height: 500px;
  width: 100%;
  margin-bottom: 20px;
}

.custom-pin {
  width: 50px;
  height: 50px;
  background: url('../images/pin.png') no-repeat center;
  background-size: contain;
  display: block;
  transform: translate(-50%, -50%);
}

.ant-list-item-meta-description {
  span {
    margin: 0 5px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.text-right {
  text-align: right;
}

.clear {
  margin-bottom: 15px;

  &:after {
    clear: both;
    content: '';
    display: block;
  }
}

html body {
  --antd-wave-shadow-color: $mainColor;
}

.app-main {
  ::selection {
    background: $mainColor;
  }

  .ant-avatar > img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    width: auto;
    height: auto;
  }

  a:active,
  a {
    color: $darkGreen;
  }

  .ant-pagination-item-active:focus a,
  .ant-pagination-item-active:hover a,
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a {
    color: $hoverGreen;
  }

  .ant-pagination-item-active a {
    color: $darkGreen;
  }

  .ant-pagination-item-active a {
    color: $mainColor;
  }

  .ant-pagination-item-active,
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: $mainColor;
  }

  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
  .ant-radio-button-wrapper-checked:first-child,
  .ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper-checked:hover {
    color: $mainColor;
    border-color: $mainColor;
  }

  .ant-input:hover,
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-upload.ant-upload-select-picture-card:hover,
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled),
  .ant-radio-checked .ant-radio-inner {
    border-color: $mainColor;
  }

  .ant-menu-item a {
    color: rgba(0, 0, 0, 0.65);
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: $mainColor;
  }

  .ant-radio-button-wrapper-checked:hover,
  .ant-radio-button-wrapper-checked {
    box-shadow: -1px 0 0 0 $mainColor;
  }

  .ant-input:focus {
    border-color: $hoverGreen;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px $lightGreen;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: $mainColor;
  }

  .ant-tabs-ink-bar,
  .ant-radio-inner::after,
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $mainColor;
  }

  .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
    background: linear-gradient(to right, $mainColor, $mainColor);
  }

  .ant-radio-button-wrapper:hover {
    color: $mainColor;
  }

  .header-white {
    background: #fff;
    padding: 15px 50px;
    height: auto;
  }

  .header-breadcrumb {
    background: #fff;
    height: 40px;
    line-height: 40px;
    padding-left: 16px;

    .bread-content {
      display: flex;

      .bread-inverse {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;

        .bread-remove {
          color: #4ec513;
          margin-left: 20px;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .ant-upload.ant-upload-select-picture-card {
    table-layout: fixed;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  ul li.ant-list-item.user-activity-list-body {
    display: block;

    ul.ant-list-item-action {
      float: none;
      margin: 25px 0 0 0;
    }
  }

  .red-icon {
    color: red;
  }

  .user-meta {
    display: flex;
    gap: 20px;

    &__delimeted {
      .user-meta__details {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 1px;
          background-color: $borderColor;
          height: calc(100% + 48px);
          margin: auto;
        }

        &:nth-child(2) {
          min-width: 380px;
          gap: 8px;
          flex: 1;
          padding-right: 10px;
        }
        &:nth-child(3) {
          margin-left: 30px;
          min-width: 250px;
        }
      }

      @media all and (max-width: 1260px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .user-meta__details {
          align-items: center;

          &::after {
            display: none;
          }
          &:nth-child(2) {
            min-width: unset;
            padding-right: 0;
            text-align: center;
          }
          &:nth-child(3) {
            margin-left: 0;
          }

          &__button-group {
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    &__details {
      display: flex;
      flex-direction: column;
      min-width: 220px;
      max-width: 400px;
      word-break: break-all;

      &-address {
        word-break: break-word;

        &__no-address {
          color: #ff0000;
        }
      }

      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-col-xs-12 {
        width: initial;
      }

      &__button-group {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 100%;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;
        gap: 15px 10px;
        width: 100%;

        &--user {
          display: flex;
          flex-wrap: wrap;
          gap: 15px 10px;
        }
      }
    }

    &__paying-user {
      text-align: center;
      margin-top: 15px;
      position: relative;
      padding-left: 10px;

      &::after {
        content: ' ';
        display: block;
        position: absolute;
        top: 6px;
        left: 3px;
        border-radius: 100%;
        width: 6px;
        height: 6px;
        background-color: greenyellow;
      }
    }
  }

  .user-card__span {
    font-size: 15px;
    max-width: calc(100% - 52px);
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
  }

  .display-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .margin-top {
    margin-top: 45px;
  }

  .main-content {
    min-height: calc(100vh - 64px);
  }

  .d-flex {
    display: flex;
  }

  .member-avatar {
    margin-right: 5px;
    margin-top: 5px;
  }
}

.breadcrumb {
  a {
    display: inline-block;
    @include transition(0.2s ease);
  }
}

.capitalize {
  text-transform: capitalize;
}

.pull-right {
  float: right;
}

.feedback-drawer {
  .ant-form-item label {
    font-weight: 500;
  }

  .feedback-row {
    margin: 10px 0;
  }

  .text-right {
    text-align: right;
  }

  .ant-btn.ant-btn-primary {
    border-color: $mainColor;
    color: #fff;
    background-color: $mainColor;
  }

  .ant-input:hover {
    border-color: $mainColor;
  }

  .ant-input:focus {
    border-color: $hoverGreen;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px $lightGreen;
  }
}

.feedback-table {
  p {
    display: flex;

    &.ant-empty-description {
      display: block;
    }

    span {
      flex: 1;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
    }
  }
}

.goal-list {
  display: block;
  white-space: nowrap;
  font-size: 12px;

  &:before {
    content: '';
    width: 14px;
    height: 10px;
    margin-right: 3px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3Cg%3E%3Ctitle%3Ebackground%3C/title%3E%3Crect fill='none' id='canvas_background' height='12' width='12' y='-1' x='-1'/%3E%3C/g%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cg stroke='null' id='svg_1'%3E%3Cpath fill='%23f5222d' stroke='null' id='svg_2' d='m9.528817,2.011328l-2.944874,2.976048l2.944874,2.975907c0.438036,0.442796 0.438036,1.160065 0,1.602861c-0.218864,0.221158 -0.50581,0.331822 -0.792616,0.331822c-0.28728,0 -0.574254,-0.110494 -0.792951,-0.331822l-2.945516,-2.976246l-2.945293,2.976217c-0.218836,0.221158 -0.50581,0.331822 -0.792867,0.331822c-0.286973,0 -0.573751,-0.110494 -0.792783,-0.331822c-0.438036,-0.442598 -0.438036,-1.159896 0,-1.602861l2.944791,-2.975907l-2.944958,-2.97602c-0.438036,-0.442626 -0.438036,-1.160065 0,-1.602691c0.437952,-0.442288 1.147531,-0.442288 1.58565,0l2.945432,2.976048l2.945209,-2.976048c0.438203,-0.442288 1.147866,-0.442288 1.585734,0c0.438203,0.442626 0.438203,1.160065 0.000167,1.602691z'/%3E%3C/g%3E%3Cg id='svg_3'/%3E%3Cg id='svg_4'/%3E%3Cg id='svg_5'/%3E%3Cg id='svg_6'/%3E%3Cg id='svg_7'/%3E%3Cg id='svg_8'/%3E%3Cg id='svg_9'/%3E%3Cg id='svg_10'/%3E%3Cg id='svg_11'/%3E%3Cg id='svg_12'/%3E%3Cg id='svg_13'/%3E%3Cg id='svg_14'/%3E%3Cg id='svg_15'/%3E%3Cg id='svg_16'/%3E%3Cg id='svg_17'/%3E%3C/g%3E%3C/svg%3E");
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 3px;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.active:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='10'%3E%3Cg%3E%3Ctitle%3Ebackground%3C/title%3E%3Crect fill='none' id='canvas_background' height='12' width='16' y='-1' x='-1'/%3E%3C/g%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cg stroke='null' id='svg_1'%3E%3Cpath stroke='null' fill='%2301c452' id='svg_2' d='m13.186183,2.463754l-6.933094,6.932608c-0.513404,0.513501 -1.346226,0.513501 -1.860117,0l-3.578911,-3.57917c-0.513599,-0.513501 -0.513599,-1.346421 0,-1.860019c0.513696,-0.513696 1.346453,-0.513696 1.859922,-0.000195l2.649323,2.649355l6.002663,-6.002695c0.513696,-0.513696 1.346518,-0.513307 1.860019,0c0.513501,0.513599 0.513501,1.346226 0.000195,1.860117z'/%3E%3C/g%3E%3Cg id='svg_3'/%3E%3Cg id='svg_4'/%3E%3Cg id='svg_5'/%3E%3Cg id='svg_6'/%3E%3Cg id='svg_7'/%3E%3Cg id='svg_8'/%3E%3Cg id='svg_9'/%3E%3Cg id='svg_10'/%3E%3Cg id='svg_11'/%3E%3Cg id='svg_12'/%3E%3Cg id='svg_13'/%3E%3Cg id='svg_14'/%3E%3Cg id='svg_15'/%3E%3Cg id='svg_16'/%3E%3Cg id='svg_17'/%3E%3C/g%3E%3C/svg%3E");
  }
}

.drawer-img {
  width: 60px;
  height: 60px;
  display: inline-block;
  border: 1px solid #ccc;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
  margin: 0 5px 0 5px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.ant-menu-submenu-selected {
  color: $mainColor;
}

.max-4-lines {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 80px;
  width: 50%;
}

.ant-skeleton {
  .ant-skeleton-content {
    .ant-skeleton-title {
      margin-top: 12px;
    }

    .ant-skeleton-paragraph {
      margin: 0;
    }
  }
}

.w-100 {
  width: 100%;
}

.m {
  &t {
    &-15 {
      margin-top: 15px;
    }
  }

  &r {
    &-10 {
      margin-left: 10px;
    }

    &-15 {
      margin-right: 15px;
    }
  }

  &b {
    &-5 {
      padding-bottom: 5px;
    }

    &-15 {
      margin-bottom: 15px;
    }

    &-20 {
      margin-bottom: 20px;
    }
  }

  &l {
    &-15 {
      margin-left: 15px;
    }
  }
}

.p {
  &t {
    &-15 {
      padding-top: 15px;
    }
  }

  &r {
    &-10 {
      padding-left: 10px;
    }

    &-15 {
      padding-right: 15px;
    }
  }

  &b {
    &-15 {
      padding-bottom: 15px;
    }

    &-20 {
      padding-bottom: 20px;
    }
  }

  &l {
    &-15 {
      padding-left: 15px;
    }
  }
}

.m-dropdown-width-auto {
  @media all and (min-width: 1025px) {
    width: auto !important;
  }
}

.m-export-comments-statistic {
  &__range-picker {
    width: 250px;
  }

  .mt-30 {
    margin-top: 30px;
  }
}

.flex {
  display: flex;

  &-justify {
    &-start {
      justify-content: flex-start;
    }

    &-end {
      justify-content: flex-end;
    }
  }

  &-align {
    &-start {
      align-items: start;
    }

    &-baseline {
      align-items: baseline;
    }
  }
}

.no-wrap {
  flex-wrap: nowrap;
}

.hidden-column {
  padding: 0 !important;
  border-right: none !important;
}

.invite-sender-wrapper {
  width: 50%;
  display: inline-flex;
  gap: 15px;

  .green {
    color: #4ec513;
  }

  .red {
    color: #dd5f64;
  }
}

b,
strong {
  @include switzer-bold;
}

.inline {
  display: flex;
  gap: 5px;
}

.upload-hide-actions .ant-upload-list-item-card-actions {
  display: none;
}

.invoicing-items {
  &__item {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #e8e8e8;

    &:last-child {
      border-bottom: none;
    }
  }
}

.edit-offer {
  position: relative;

  > .ant-tabs-top-bar {
    margin-bottom: 0;
    background-color: #fff;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.bg-gray {
  background-color: rgba(128, 128, 128, 0.15);
}

.scrolling-list {
  .ant-list-items {
    overflow-x: auto;

    @media all and (max-width: 1260px) {
      display: grid;
      grid-auto-columns: minmax(max-content, 1fr);
      grid-auto-flow: row;
    }
  }

  &.d-block {
    .ant-list-items {
      display: block;
    }
  }

  .ant-table-body {
    overflow-x: auto !important;
  }
}

.ant-modal {
  max-width: calc(100vw - 16px);
}

.item-list-start {
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;

  .ant-list-item-action {
    margin-left: 0 !important;
    width: 100%;
  }
}

.ant-calendar.ant-calendar-range {
  @media all and (max-width: 766px) {
    width: 100%;

    .ant-calendar-date-panel {
      display: inline-grid;
    }

    .ant-calendar-range-part {
      width: 100%;
    }
  }
}

.ant-btn.primary-border-btn {
  border-color: $mainColor;
}
