.list-items {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  li {
    position: relative;
    padding: 0 8px;
  }
}
