@import '../../../assets/sass/variables';

.headerInfo {
  position: relative;
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > span {
    display: inline-block;
    margin-bottom: 4px;
    line-height: 22px;
  }

  & > p {
    margin: 0;
    font-size: 24px;
    line-height: 32px;
    color: $textColor;
  }

  & > em {
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: $borderColor;
  }

  &-legend {
    color: $greyColor;
  }
}
