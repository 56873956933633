.login-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 450px;
  background: #f0f2f5;

  .session-wrapper {
    background: #ffffff;
    width: 400px;
    border: 1px solid #e8e8e8;
    padding: 15px;
    border-radius: 4px;
  }
  .site-logo {
    text-align: center;
    margin-bottom: 30px;
    border: 0;

    img {
      max-width: 80px;
    }
  }
  .ant-form-item {
    margin-bottom: 15px;
  }
}

.login-confirm-form {
  font-weight: 500;

  &-alert {
    text-align: center;
  }

  &-button {
    margin-bottom: 20px;
  }

  &-text {
    text-align: center;
    margin-bottom: 10px;
  }

  &-resend {
    padding: 0 !important;
    color: #1890ff !important;
    border: none !important;
    background-color: inherit !important;
    height: 21px !important;
    display: inline-block;
  }
  &-countdown {
    font-size: 14px !important;
    font-weight: 400 !important;
    span {
      font-size: 14px !important;
      font-weight: 400 !important;
    }
  }
}
